export const baseUrl =
  process.env.NEXT_PUBLIC_BASE_URL || "http://dev.moises.ai:3000";

export const strapiUrl = process.env.STRAPI_API_URL || "";

export const isProd = process.env.NODE_ENV === "production";

export const isDev = process.env.NODE_ENV === "development";

export const isPreview = process.env.PREVIEW_ENV === "true" || false;

export const isBrowser = typeof window !== "undefined";

export const gaTrackId = process.env.NEXT_PUBLIC_GA_TRACK_ID || "";

export const fbTrackId = process.env.NEXT_PUBLIC_FACEBOOK_TRACK_ID || "";

export const linkedinTrackId = process.env.NEXT_PUBLIC_LINKEDIN_TRACK_ID || "";
