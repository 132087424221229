import { textVariant, type TextVariantProps } from "./text.variants";

export type UseTextProps = TextVariantProps & {
  className?: string;
  id?: string;
  tag?:
    | "p"
    | "span"
    | "small"
    | "strong"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "time"
    | "cite"
    | "q"
    | "em"
    | "code";
  children?: React.ReactNode;
  style?: Record<string, string | number>;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
};

export const useText = (props: UseTextProps) => {
  const {
    className = "",
    tag = "p",
    children,
    theme,
    size: sizeProp,
    weight,
    uppercase = false,
    italic = false,
    ...componentProps
  } = props;

  const isTitle = ["h1", "h2", "h3", "h4", "h5", "h6"];
  const size = (
    isTitle.includes(tag) ? sizeProp || tag : sizeProp
  ) as TextVariantProps["size"];

  const styles = textVariant({
    theme,
    size,
    weight,
    uppercase,
    italic,
  });

  return {
    Component: tag,
    theme,
    className,
    styles,
    children,
    componentProps,
  };
};
